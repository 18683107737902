$black: #000000;
$white: #ffffff;


$red-variations: (
  500: #963634,
  100: #F2DCDB,
);

$grey-variations: (
  600: #212529,
  500: #3d3d3d,
  400: #505459,
  300: #73767b,
  200: #dddede,
  100: #fafafa,
  500_40: #3d3d3d40,
);

$green-variations: (
  100: #adebbb,
);
