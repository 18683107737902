// valid font weights, anything else should not be used in the app
$font-light: 300;
$font-regular: 400;
$font-medium: 500;
$font-bold: 700;

// breakpoints
$breakpoints: (
  xsmall: 320px,
  small: 375px,
  medium: 768px,
  large: 1025px,
  xlarge: 1200px,
  xxlarge: 1440px,
  xxxlarge: 1680px,
  xxxxlarge: 1920px,
);

$transition: 150ms;

@mixin breakpoint($breakpoint) {
  $size: map-get($breakpoints, $breakpoint);

  @media screen and (min-width: $size) {
    @content;
  }
}

@font-face {
  font-family: 'BrownStd';
  src: url('./fonts/BrownStd-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'BrownStd-Light';
  src: url('./fonts/BrownStd-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'BrownStd-Bold';
  src: url('./fonts/BrownStd-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

$brown-std: 'BrownStd', sans-serif;
$brown-std-light: 'BrownStd-Light', sans-serif;
$brown-std-bold: 'BrownStd-Bold', sans-serif;

$z-index-back: 0;
$z-index-front: 1;
$z-index-front-2: 2;
$z-index-nav: 5;
$z-index-overlay: 10;

@mixin ellipsisOverflow {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

$row-height: 53px;
