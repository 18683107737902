@import '../../../styles/colors.scss';
@import '../../../styles/variables';

.header {
  font-family: $brown-std-bold;
  text-align: center;
  background-color: map-get($red-variations, 500);
  color: $white;
}

.cardHeader {
  font-family: $brown-std-bold;
}

.icon {
  color: map-get($red-variations, 500);
}

.pointerEnabled {
  color: map-get($red-variations, 500);
  cursor: pointer;
}

.blink {
  background-color: map-get($green-variations, 100);
  animation: blinker 2s linear infinite;
}

.row {
  padding: 16px 0;
  font-weight: $font-medium;
  text-align: center;
}

.pointerDisabled {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 50%;
}
