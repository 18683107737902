@import '../../styles/colors.scss';
@import '../../styles/variables';

.loading {
  left: 50%;
  margin-left: -50px;
  margin-top: -50px;
  position: fixed;
  top: 50%;
  z-index: 9999999;
  color: map-get($red-variations, 500);
}
