@import '../../../styles/colors.scss';
@import '../../../styles/variables';

.header {
  font-family: $brown-std-bold;
  text-align: center;
  background-color: map-get($red-variations, 500);
  color: $white;
}

.cardHeader {
  font-family: $brown-std-bold;
  text-align: center;
}

.icon {
  color: map-get($red-variations, 500);
}

.pointerEnabled {
  color: map-get($red-variations, 500);
  cursor: pointer;
}

.row {
  padding: 16px 16px;
  font-weight: $font-medium;
  text-align: left;
}

.pointerDisabled {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 50%;
}
