@import '../../styles/colors.scss';
@import '../../styles/variables';

.card {
  background-color: $white;
  margin: auto;
  margin-top: 50px;
  border-radius: 8px;
  border: 1px solid map-get($grey-variations, 200);
  max-width: 500px;
  padding: 40px 40px;
}

.buttonRow {
  margin-top: 48px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-direction: row;
  padding-top: 20px;
}

.button {
  max-width: 350px;
  position: absolute;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
