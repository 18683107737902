@import '../../styles/colors.scss';
@import '../../styles/variables';

.container {
  margin-left: 20px;
  padding: 0 0;
}

.image {
  width: 50%;
  height: 50%;
}
