@import '../../../styles/colors.scss';
@import '../../../styles/variables';

.table {
  vertical-align: middle;
}

.header {
  background-color: map-get($red-variations, 500);
  color: $white;
  font-family: $brown-std-bold;
  text-align: center;
  width: 10%;
}

.textColumn {
  width: 10%;
  text-align: left;
}

.iconColumn {
  color: map-get($red-variations, 500);
  width: 10%;
  text-align: center;
}

.row {
  height: $row-height;
}
