@import '../../../styles/colors.scss';
@import '../../../styles/variables';

.table {
  vertical-align: middle;
}

.header {
  background-color: map-get($red-variations, 500);
  color: $white;
  font-family: $brown-std-bold;
  text-align: center;
  width: 10%;
}

.ativoColumn {
  width: 5%;
  text-align: center;
}

.textColumn {
  width: 10%;
  text-align: center;
}

.iconColumn {
  color: map-get($red-variations, 500);
  width: 5%;
  text-align: center;
}

.buttonColumn {
  width: 5%;
  text-align: center;
}

.buttonAction {
  border: none;
  color: $black;
}

.buttonAction:hover {
  color: $black;
  background-color: map-get($grey-variations, 100);
  border-color: $black;
  box-shadow: none;
  border: none;
}

.buttonAction:focus {
  color: $black;
  background-color: map-get($grey-variations, 100);
  border: none;
  box-shadow: none;
}

.buttonAction:active {
  color: $black;
  background-color: map-get($grey-variations, 100);
  border: none;
  box-shadow: none;
}

.buttonAction:active:focus {
  border: none;
  box-shadow: none;
}

.row {
  height: $row-height;
}
