@import '../../../styles/colors.scss';
@import '../../../styles/variables';

.header {
  background-color: map-get($red-variations, 500);
  color: $white;
  text-align: center;
  font-weight: $font-bold;
}

.buttonContainer {
  text-align: right;

  button {
    margin: 0px 2px 0px 2px;
  }
}
