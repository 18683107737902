@import '../../../styles/colors.scss';
@import '../../../styles/variables';

.header {
  font-family: $brown-std-bold;
  text-align: center;
  background-color: map-get($red-variations, 500);
  color: $white;
}

.cardHeader {
  font-family: $brown-std;
}

.row {
  padding: 16px 16px;
  font-weight: $font-medium;
}

.period {
  text-decoration: none;
  cursor: pointer;
}

.periodSelected {
  text-decoration: underline;
}
