@import './styles/colors';
@import './styles/variables';

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: $brown-std;
  font-weight: $font-regular;
  background-color: map-get($grey-variations, 100);
}

.btn-outline-primary {
  color: map-get($red-variations, 500);
  border-color: map-get($red-variations, 500);
  background-color: map-get($grey-variations, 100);
}

.btn-outline-primary:hover {
  background-color: map-get($red-variations, 500);
  border-color: map-get($red-variations, 500);
  color: $white;
}

.btn-outline-primary:hover.disabled {
  background-color: $white;
  color: map-get($red-variations, 500);
  border-color: map-get($red-variations, 500);
}

.btn-outline-primary:active {
  background-color: map-get($red-variations, 500);
  color: $white;
  border-color: map-get($red-variations, 500);
  box-shadow: 0 0 0 0.2rem map-get($red-variations, 500);
}

.btn-outline-primary:focus-visible {
  background-color: map-get($red-variations, 500);
  color: $white;
  border-color: map-get($red-variations, 500);
  box-shadow: 0 0 0 0.2rem map-get($red-variations, 500);
}

.btn-outline-primary:focus {
  box-shadow: 0 0 0 0.2rem map-get($red-variations, 500);
  background-color: map-get($red-variations, 500);
  border-color: map-get($red-variations, 500);
  color: $white;
}

.btn-outline-primary:active:focus {
  box-shadow: 0 0 0 0.2rem map-get($red-variations, 500);
  background-color: map-get($red-variations, 500);
  border-color: map-get($red-variations, 500);
}

.btn-outline-primary:disabled {
  color: map-get($red-variations, 500);
  border-color: map-get($red-variations, 500);
}

.page-link {
  background-color: map-get($grey-variations, 100);
  color: map-get($red-variations, 500);
}

.page-link:hover {
  background-color: map-get($red-variations, 100);
  color: $black;
}

.page-link:focus {
  background-color: map-get($red-variations, 100);
  color: $black;
  box-shadow: none;
}

.page-link:hover:focus {
  box-shadow: none;
}

.page-item.active .page-link {
  background-color: map-get($red-variations, 500);
  color: $white;
  border-color: map-get($red-variations, 500);
}
